import Icon from '@common/assets';
import PreviewProfileTitle from '@components/position/jd/apply/modals/previewProfileModal/previewProfileTitle';
import ProjectContents from '@components/profile/userInfoItems/project/ProjectContents';
import ProfileProjectsVO from '@domain/vo/profile/ProfileProjectVO';

import classnames from 'classnames/bind';
import styles from './previewProfileModal.module.scss';
const cx = classnames.bind(styles);

interface ProjectProps {
  profileProjects: ProfileProjectsVO | null;
}

const Project = ({ profileProjects }: ProjectProps) => {
  if (!profileProjects) return null;

  return (
    <div className={cx('viewWrap')}>
      <PreviewProfileTitle icon={<Icon name="projectLight" width={40} height={40} />} title="프로젝트" />
      <ProjectContents projectList={profileProjects.projects} />
    </div>
  );
};

export default Project;
