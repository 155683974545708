import Icon from '@components/common/assets';
import PreviewProfileTitle from '@components/position/jd/apply/modals/previewProfileModal/previewProfileTitle';
import MilitaryPreferenceContents from '@components/profile/userInfoItems/militaryPreference/MilitaryPreferenceContents';
import ProfilePreferentialVO from '@domain/vo/profile/ProfilePreferentialVO';

import classnames from 'classnames/bind';
import styles from './previewProfileModal.module.scss';
const cx = classnames.bind(styles);

interface MilitaryPreferenceProps {
  profilePreferential: ProfilePreferentialVO | null;
}

const MilitaryPreference = ({ profilePreferential }: MilitaryPreferenceProps) => {
  if (!profilePreferential) return null;

  return (
    <div className={cx('viewWrap')}>
      <PreviewProfileTitle icon={<Icon name="militaryLight" width={40} height={40} />} title="병역 · 취업 우대" />
      <MilitaryPreferenceContents profilePreferential={profilePreferential} />
    </div>
  );
};

export default MilitaryPreference;
