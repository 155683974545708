import React, { FC } from 'react';
import Icon from '@common/assets';
import ResearchContents from '@components/profile/userInfoItems/edu/ResearchContents';
import MajorContents from '@components/profile/userInfoItems/edu/MajorContents';
import { EducationVO } from '@domain/vo/profile/ProfileEducationVO';

import { getEducationDateText } from '@domain/constant/profile/EducationType';
import classnames from 'classnames/bind';
import styles from './edu.module.scss';
const cx = classnames.bind(styles);

interface IEducationProps {
  education: EducationVO;
}

const Education: FC<IEducationProps> = ({ education }) => {
  const {
    schoolLevelText,
    schoolName,
    educationType,
    transferYn,
    startDate,
    endDate,
    educationTypeText,
    profileMajors,
    profileResearches,
    auditItems,
  } = education;

  const isAutoSaved =
    auditItems?.includes('PROFILE_EDUCATION_START_DATE') || auditItems?.includes('PROFILE_EDUCATION_END_DATE');
  return (
    <div className={cx('infoWrap')}>
      {/* 학교 이름 */}
      <em className={cx('name')}>{schoolName}</em>
      {/* 재학 기간 */}
      <div className={cx('period')}>
        <Icon name="calenderLight" width={24} height={24} />
        <span className={cx({ autoSave: isAutoSaved })}>
          {getEducationDateText({ educationType, startDate, endDate, educationTypeText })}
        </span>
      </div>
      {/* 학교 구분 */}
      <div className={cx('type')}>
        {schoolLevelText}
        {transferYn && <span className={cx('transferText')}>*편입</span>}
      </div>
      {/* 학과, 전공 */}
      <MajorContents majorList={profileMajors} isAutoSaved={auditItems?.includes('PROFILE_EDUCATION_MAJOR')} />
      {/* 연구분야, 논문 */}
      <ResearchContents researchList={profileResearches} />
    </div>
  );
};

interface IEduContentsProps {
  educationList: EducationVO[];
}

const EduContents: FC<IEduContentsProps> = ({ educationList }) => {
  const eduContentsEl = educationList.map((item, idx) => {
    return <Education key={idx} education={item} />;
  });

  return <div className={cx('infoInner')}>{eduContentsEl}</div>;
};

export default EduContents;
