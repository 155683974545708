import React from 'react';
import Icon from '@components/common/assets';
import { UploadFile } from '@components/common/upload';
import { useDownloadFile } from '@repository/file/useFiles';
import { AttachFileDto } from '@domain/rs/position/jd/ProfileDetailRs';
import classnames from 'classnames/bind';
import styles from './addtionInfo.module.scss';
const cx = classnames.bind(styles);

interface Props {
  question: string;
  files: Pick<AttachFileDto, 'fileName' | 'fileUid'>[];
}

const File = ({ question, files }: Props) => {
  const { mutateAsync: downloadFile } = useDownloadFile();
  const handleDownload = async (e: React.MouseEvent<HTMLButtonElement>, file: UploadFile) => {
    e.preventDefault();
    await downloadFile(file);
  };

  return (
    <div>
      <h4 className={cx('question')}>{question}</h4>
      <div className={cx('fileArea')}>
        {files?.map((file) => (
          <div key={file.fileUid} className={cx('file')}>
            <Icon name="linkLineLight" width={32} height={32} />
            <button
              className={cx('downloadButton')}
              onClick={(e) => handleDownload(e, { fileName: file.fileName, fileUid: file.fileUid, status: 'done' })}
            >
              {file.fileName}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default File;
