import ProfileBasicVO from '@domain/vo/profile/ProfileBasicVO';

import classnames from 'classnames/bind';
import styles from './basicInfo.module.scss';
const cx = classnames.bind(styles);

interface BasicInfoProps {
  basicInfo: Pick<
    ProfileBasicVO,
    'name' | 'profileImageUrl' | 'email' | 'birthDateText' | 'mobileText' | 'countryCode'
  > | null;
}

const BasicInfo = ({ basicInfo }: BasicInfoProps) => {
  if (!basicInfo) return null;

  const { name, profileImageUrl, email, birthDateText, mobileText, countryCode } = basicInfo;
  return (
    <div className={cx('viewResumeWrap', 'basicInfo')}>
      <div
        className={cx('profileImage', { default: !profileImageUrl })}
        style={profileImageUrl ? { backgroundImage: `url(${profileImageUrl})` } : undefined}
      />
      <div className={cx('basicInfoInner')}>
        {/* 이름 */}
        {name && <div className={cx('name')}>{name}</div>}
        <div className={cx('subInfoArea')}>
          {/* 생일 */}
          {birthDateText && <span className={cx('subInfo', 'birthday')}>{birthDateText}</span>}
          {/* 번호 */}
          {mobileText && <span className={cx('subInfo', 'mobile')}>{`+${countryCode} ${mobileText}`}</span>}
          {/* 이메일 */}
          {email && <span className={cx('subInfo', 'mail')}>{email}</span>}
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
