import { useRef } from 'react';
import { UseQueryOptions, useMutation } from 'react-query';
import FileRs from '@domain/rs/common/FileRs';
import FileVO from '@domain/vo/common/FileVO';
import { RemoteFileRepo } from '@repository/file/FileRepo';
import FileUtil from '@utils/fileUtil';

const FAILD_FILE_UID = 0;
const remoteFileRepo = new RemoteFileRepo();

const fetchUploadFile = async ({ authYn, file }: { authYn: boolean; file: File }) => {
  const { data } = await remoteFileRepo.uploadFile(authYn, file);
  return data;
};

export const useUploadFile = (options?: UseQueryOptions) => {
  const fileRef = useRef<File | null>(null);
  return useMutation(
    ({ authYn = true, file }: { authYn?: boolean; file: any }) => {
      fileRef.current = file;
      return fetchUploadFile({ authYn, file });
    },
    {
      ...(options as any),
      onError: (e) => {
        options?.onError?.(e);
        const temp = { fileName: fileRef?.current?.name, fileUid: `${FAILD_FILE_UID + 1}` } as FileRs;
        return new FileVO(temp, 'error');
      },
    },
  );
};

const fetchDownloadFile = async (fileInfo: FileRs) => {
  const { data } = await remoteFileRepo.downloadFile(fileInfo.fileUid);
  FileUtil.fileDownload(fileInfo.fileName, data);
};

export const useDownloadFile = (options?: UseQueryOptions) => {
  return useMutation(fetchDownloadFile, options as any);
};
