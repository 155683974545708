import React, { FC } from 'react';
import Icon from '@common/assets';
import { PrizeVO } from '@domain/vo/profile/ProfilePrizeVO';

import classnames from 'classnames/bind';
import styles from './prize.module.scss';
const cx = classnames.bind(styles);

interface IDescriptionProps {
  description: string;
}

const Description: FC<IDescriptionProps> = ({ description }) => {
  if (!description.length) return null;

  return <div className={cx('description')}>{description}</div>;
};

interface IPrizeProps {
  prize: PrizeVO;
}

const Prize: FC<IPrizeProps> = ({ prize }) => {
  const { institution, date, name, description, auditItems } = prize;

  return (
    <div className={cx('infoWrap')}>
      {/* 수상명 */}
      <em className={cx('name')}>{name}</em>
      {/* 수상 일자 */}
      {date && (
        <div className={cx('period')}>
          <Icon name="calenderLight" width={24} height={24} />
          <span className={cx({ autoSave: auditItems?.includes('PROFILE_PRIZE_DATE') })}>{date}</span>
        </div>
      )}
      {/* 수여 기관 */}
      <div className={cx('agency')}>{institution}</div>
      {/* 수상 내용 */}
      <Description description={description} />
    </div>
  );
};

interface IPrizeContentsProps {
  prizes: PrizeVO[];
}

const PrizeContents: FC<IPrizeContentsProps> = ({ prizes }) => {
  if (!prizes.length) return null;

  const prizesEl = prizes.map((item, idx) => {
    return <Prize key={idx} prize={item} />;
  });

  return <div className={cx('infoInner')}>{prizesEl}</div>;
};

export default PrizeContents;
