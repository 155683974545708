import React, { FC } from 'react';
import ExperienceList from '@components/profile/userInfoItems/experience/ExperienceList';
import ProfileExperienceVO, { EtcExperiencesVO } from '@domain/vo/profile/ProfileExperienceVO';

import classnames from 'classnames/bind';
import styles from './experience.module.scss';
const cx = classnames.bind(styles);

interface IEtcDescContentsProps {
  experienceList: EtcExperiencesVO[];
}

const EtcDescContents: FC<IEtcDescContentsProps> = ({ experienceList }) => {
  if (!experienceList.length) return null;

  const experienceListEl = experienceList.map((item, idx) => {
    return (
      <li key={idx} className={cx('listItem')}>
        {item.description}
      </li>
    );
  });
  return (
    <div className={cx('etcArea')}>
      <div className={cx('infoWrap')}>
        {/* 기타 경험 타이틀 */}
        <em className={cx('name')}>기타 경험</em>
        {/* 기타 경험 내용 */}
        <ul className={cx('etcList')}>{experienceListEl}</ul>
      </div>
    </div>
  );
};

interface IExperienceContentsProps {
  profileExperience: ProfileExperienceVO;
}

const ExperienceContents: FC<IExperienceContentsProps> = ({ profileExperience }) => {
  const { activitiesAndForeignExperiences, experiences } = profileExperience;

  return (
    <>
      <div className={cx('infoInner')}>
        <ExperienceList experienceList={activitiesAndForeignExperiences} />
      </div>

      {/* [D] 기타 경험 영역 / 최하단에 위치 */}
      <EtcDescContents experienceList={experiences} />
    </>
  );
};

export default ExperienceContents;
