import Icon from '@components/common/assets';
import PreviewProfileTitle from '@components/position/jd/apply/modals/previewProfileModal/previewProfileTitle';
import ExperienceContents from '@components/profile/userInfoItems/experience/ExperienceContents';
import ProfileExperienceVO from '@domain/vo/profile/ProfileExperienceVO';

import classnames from 'classnames/bind';
import styles from './previewProfileModal.module.scss';
const cx = classnames.bind(styles);

interface ExperienceProps {
  profileExperience: ProfileExperienceVO | null;
}

const Experience = ({ profileExperience }: ExperienceProps) => {
  if (!profileExperience) return null;

  return (
    <div className={cx('viewWrap')}>
      <PreviewProfileTitle icon={<Icon name="readBookLight" width={40} height={40} />} title="경험" />
      <ExperienceContents profileExperience={profileExperience} />
    </div>
  );
};

export default Experience;
