import { AxiosResponse } from 'axios';
import FileRs from '@domain//rs/common/FileRs';
import { axios } from '@repository/RequestClient';

export default interface FileRepo {
  // 파일 업로드
  uploadFile(authYn: boolean, file: File): Promise<AxiosResponse<FileRs>>;

  // 파일 다운로드
  downloadFile(uid: string): Promise<AxiosResponse<ArrayBuffer>>;
}

export class RemoteFileRepo implements FileRepo {
  uploadFile(authYn: boolean, file: File) {
    const formData: FormData = new FormData();
    formData.append('authYn', `${authYn}`);
    formData.append('file', file);
    return axios.post(`/file`, formData);
  }

  downloadFile(uid: string) {
    return axios.get<ArrayBuffer>(`/file/${uid}/download`, { responseType: 'arraybuffer' });
  }
}
