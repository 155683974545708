import React, { FC } from 'react';
import { MajorVO } from '@domain/vo/profile/ProfileEducationVO';
import { Major } from '@domain/constant/profile/EducationType';

import classnames from 'classnames/bind';
import styles from './edu.module.scss';
const cx = classnames.bind(styles);

interface IMajorItemProps {
  vo: MajorVO;
  isAutoSaved?: boolean;
}

const MajorItem: FC<IMajorItemProps> = ({ vo, isAutoSaved }) => {
  const { majorTypeText, majorName, scoreText } = vo;

  return <li className={cx('listItem', { autoSave: isAutoSaved })}>{`${majorTypeText} : ${majorName}${scoreText}`}</li>;
};

interface IMajorContentsProps {
  majorList: MajorVO[];
  isAutoSaved?: boolean;
}

const MajorContents: FC<IMajorContentsProps> = ({ majorList, isAutoSaved }) => {
  const majorListEl = majorList
    .filter((item) => item.majorType === Major.MAJOR)
    .map((item, idx) => {
      return <MajorItem key={idx} vo={item} isAutoSaved={isAutoSaved} />;
    });

  const minorListEl = majorList
    .filter((item) => item.majorType === Major.MINOR)
    .map((item, idx) => {
      return <MajorItem key={idx} vo={item} />;
    });

  const doubleListEl = majorList
    .filter((item) => item.majorType === Major.DOUBLE)
    .map((item, idx) => {
      return <MajorItem key={idx} vo={item} />;
    });

  return (
    <ul className={cx('majorList')}>
      {majorListEl}
      {minorListEl}
      {doubleListEl}
    </ul>
  );
};

export default MajorContents;
