import React, { FC, useState } from 'react';
import Icon from '@common/assets';
import FileRs from '@domain/rs/common/FileRs';
import { useDownloadFile } from '@repository/file/useFiles';
import { ResearchVO } from '@domain/vo/profile/ProfileEducationVO';

import FileUtil from '@utils/fileUtil';
import classnames from 'classnames/bind';
import styles from './edu.module.scss';
const cx = classnames.bind(styles);

interface IResearchProps {
  vo: ResearchVO;
  researchDescVisible: boolean;
  onDownloadFile: (fileInfo: FileRs) => void;
}

const Research: FC<IResearchProps> = ({ vo, researchDescVisible, onDownloadFile }) => {
  const { attachFiles, descriptionList } = vo;
  const attachFilesEl = attachFiles.map((item, idx) => {
    return (
      <a key={idx} className={cx('file')} role={'button'} onClick={() => onDownloadFile(item)}>
        <Icon name="linkLineLight" width={24} height={24} />
        <span className={cx('fileName')}>{item.fileName}</span>
      </a>
    );
  });

  const descriptionEl = descriptionList.map((description, idx) => <li key={idx}>{description}</li>);

  return (
    <div className={cx('fileWrap', { unfold: researchDescVisible })}>
      {attachFilesEl}
      {researchDescVisible && <ul className={cx('fileDesc')}>{descriptionEl}</ul>}
    </div>
  );
};

interface IResearchContentsProps {
  researchList: ResearchVO[];
}
const ResearchContents: FC<IResearchContentsProps> = ({ researchList }) => {
  const [researchDescVisible, setResearchDescVisible] = useState(false);
  const { mutate: downloadFileMutate } = useDownloadFile();
  const isDesc = researchList.some((research) => !!research.description);

  const onDownloadFile = async (fileInfo: FileRs) => {
    if (fileInfo?.fileUrl) {
      return FileUtil.fileUrlDownload(fileInfo.fileName, fileInfo?.fileUrl);
    }
    await downloadFileMutate(fileInfo);
  };

  const researchListEl = researchList.map((item, idx) => {
    return <Research key={idx} vo={item} researchDescVisible={researchDescVisible} onDownloadFile={onDownloadFile} />;
  });

  if (!researchList.length) return null;

  return (
    <div className={cx('fileArea')}>
      {researchListEl}
      {isDesc && (
        <a
          className={cx('btnDetail', { close: researchDescVisible })}
          role="button"
          onClick={() => setResearchDescVisible(!researchDescVisible)}
        >
          자세히 보기
          {researchDescVisible ? (
            <Icon name="arrowTopLight" width={24} height={24} />
          ) : (
            <Icon name="arrowBottomLight" width={24} height={24} />
          )}
        </a>
      )}
    </div>
  );
};

export default ResearchContents;
