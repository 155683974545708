import React from 'react';

type HtmlProps<T extends React.ElementType> = {
  as?: T;
  html: string;
} & React.ComponentPropsWithoutRef<T>;

const Html = <T extends React.ElementType = 'div'>({ as, html, ...props }: HtmlProps<T>) => {
  const Element = as || 'div';
  return <Element dangerouslySetInnerHTML={{ __html: html }} {...props} />;
};

export default Html;
