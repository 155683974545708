import React, { FC } from 'react';
import Icon from '@common/assets';
import ProfilePreferentialVO, { DisabilityVO, MilitaryVO, VeteranVO } from '@domain/vo/profile/ProfilePreferentialVO';
import { MilitaryStatus } from '@domain/constant/profile/PreferentialType';

import classnames from 'classnames/bind';
import styles from './militaryPreference.module.scss';

const cx = classnames.bind(styles);

interface IMilitaryContentsProps {
  military: MilitaryVO | null;
}

const MilitaryContents: FC<IMilitaryContentsProps> = ({ military }) => {
  if (!military) return null;

  const { typeText, militaryClassText, startDate, endDate, status, statusText, dischargeTypeText } = military;

  const dataTextEl =
    MilitaryStatus.IN_SERVICE === status ? `${startDate} ~ ${statusText}` : `${startDate} ~ ${endDate}`;

  const contentsVisible = [MilitaryStatus.DISCHARGE, MilitaryStatus.IN_SERVICE].includes(status as MilitaryStatus);
  const militaryClassDischargeTypeText = dischargeTypeText
    ? `${militaryClassText} / ${dischargeTypeText}`
    : militaryClassText;

  return (
    <div className={cx('infoWrap')}>
      <em className={cx('infoTitle')}>병역사항</em>

      <div className={cx('militaryInfoArea')}>
        {/* 군별 */}
        <em className={cx('name')}>
          {/* 군별 태그 */}
          <span className={cx('tag')}>{statusText}</span>
          {typeText}
        </em>
        {/* 병역 기간 */}
        {contentsVisible && (
          <div className={cx('period')}>
            <Icon name="calenderLight" width={24} height={24} />
            {dataTextEl}
          </div>
        )}
        {/* 계급 / 제대구분 */}
        {contentsVisible && <p className={cx('text')}>{militaryClassDischargeTypeText}</p>}
      </div>
    </div>
  );
};

interface IPreferenceContentsProps {
  disability: DisabilityVO | null;
  veteran: VeteranVO | null;
}

const PreferenceContents: FC<IPreferenceContentsProps> = ({ disability, veteran }) => {
  if (!disability && !veteran) return null;

  return (
    <div className={cx('infoWrap')}>
      <em className={cx('infoTitle')}>우대사항</em>

      <ul className={cx('preferenceList')}>
        {disability && (
          <li className={cx('listItem')}>
            <span className={cx('tag')}>장애 {disability.gradeText}</span>
            {disability.typeText}
          </li>
        )}
        {veteran && (
          <li className={cx('listItem')}>
            <span className={cx('tag')}>보훈 {veteran.statusText}</span>
            {veteran.reason}
          </li>
        )}
      </ul>
    </div>
  );
};

interface IMilitaryPreferenceContentsProps {
  profilePreferential: ProfilePreferentialVO;
}

const MilitaryPreferenceContents: FC<IMilitaryPreferenceContentsProps> = ({ profilePreferential }) => {
  const { military, disability, veteran } = profilePreferential;

  return (
    <div className={cx('infoInner')}>
      <MilitaryContents military={military} />
      <PreferenceContents disability={disability} veteran={veteran} />
    </div>
  );
};

export default MilitaryPreferenceContents;
