import React, { FC } from 'react';
import Icon from '@common/assets';
import { ProfileProjectVO, SkillsVO } from '@domain/vo/profile/ProfileProjectVO';
import { Project } from '@domain/constant/profile/ProjectType';

import classnames from 'classnames/bind';
import styles from './project.module.scss';
const cx = classnames.bind(styles);

interface ISkillsContentsProps {
  skillList: SkillsVO[];
}

const SkillsContents: FC<ISkillsContentsProps> = ({ skillList }) => {
  if (!skillList.length) return null;

  const skillsEls = skillList.map((item) => {
    return <li key={item.code} className={cx('listItem')}>{`${item.keyword} - ${item.skillLevelText}`}</li>;
  });

  return (
    <div className={cx('skillArea')}>
      <div className={cx('subTitle')}>활용 기술</div>
      <ul className={cx('skillList')}>{skillsEls}</ul>
    </div>
  );
};

interface IDescriptionProps {
  performance: string;
}

const Description: FC<IDescriptionProps> = ({ performance }) => {
  if (!performance.length) return null;

  return <div className={cx('description')}>{performance}</div>;
};

interface IProjectItemProps {
  project: ProfileProjectVO;
}

const ProjectItem: FC<IProjectItemProps> = ({ project }) => {
  const {
    title,
    startDate,
    endDate,
    teamType,
    teamTypeText,
    contribution,
    institution,
    performance,
    skills,
    auditItems,
  } = project;
  const isTeam = teamType === Project.TEAM;
  const isAutoSaved =
    auditItems?.includes('PROFILE_PROJECT_START_DATE') || auditItems?.includes('PROFILE_PROJECT_END_DATE');

  return (
    <div className={cx('infoWrap')}>
      {/* 프로젝트 이름 */}
      <em className={cx('name')}>{title}</em>
      <div className={cx('infoBox')}>
        {/* 프로젝트 기간 */}
        <div className={cx('period')}>
          <Icon name="calenderLight" width={24} height={24} />
          <span className={cx({ autoSave: isAutoSaved })}>{`${startDate} ~ ${endDate}`}</span>
        </div>

        {/* 프로젝트 형태 */}
        <div className={cx('projectTypeArea')}>
          <div className={cx('projectType')}>
            {isTeam ? (
              <Icon name="peopleLight" width={24} height={24} />
            ) : (
              <Icon name="userLight" width={24} height={24} />
            )}
            {teamTypeText}
          </div>
          {isTeam && <div className={cx('contributeRate')}>기여도 {contribution}%</div>}
        </div>
      </div>
      {/* 프로젝트 진행한 곳 */}
      {institution && <div className={cx('place')}>{institution}</div>}
      {/* 주요 성과 */}
      {performance && <Description performance={performance} />}
      {/* 활용 기술 */}
      <SkillsContents skillList={skills} />
    </div>
  );
};

interface IProjectContentsProps {
  projectList: ProfileProjectVO[];
}

const ProjectContents: FC<IProjectContentsProps> = ({ projectList }) => {
  const projectEls = projectList.map((item, idx) => {
    return <ProjectItem key={idx} project={item} />;
  });

  return <div className={cx('infoInner')}>{projectEls}</div>;
};

export default ProjectContents;
