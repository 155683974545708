import Icon from '@components/common/assets';
import PreviewProfileTitle from '@components/position/jd/apply/modals/previewProfileModal/previewProfileTitle';
import CareerContents from '@components/profile/userInfoItems/career/CareerContents';
import ProfileCareersVO from '@domain/vo/profile/ProfileCareersVO';

import classnames from 'classnames/bind';
import styles from './previewProfileModal.module.scss';
const cx = classnames.bind(styles);

interface CareerProps {
  career: ProfileCareersVO | null;
}

const Career = ({ career }: CareerProps) => {
  if (!career) return null;

  return (
    <div className={cx('viewWrap')}>
      <PreviewProfileTitle
        icon={<Icon name="workLight" width={40} height={40} />}
        title="경력"
        badgeText={career.totalCareerPeriodText}
      />
      <CareerContents careerList={career.careers} />
    </div>
  );
};

export default Career;
