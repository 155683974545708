import Icon from '@components/common/assets';
import EduContents from '@components/profile/userInfoItems/edu/EduContents';
import PreviewProfileTitle from '@components/position/jd/apply/modals/previewProfileModal/previewProfileTitle';
import ProfileEducationVO from '@domain/vo/profile/ProfileEducationVO';

import classnames from 'classnames/bind';
import styles from './previewProfileModal.module.scss';
const cx = classnames.bind(styles);

interface EduProps {
  educations: ProfileEducationVO | null;
}

const Edu = ({ educations }: EduProps) => {
  if (!educations) return null;

  return (
    <div className={cx('viewWrap')}>
      <PreviewProfileTitle
        icon={<Icon name="universityLight" width={40} height={40} />}
        title="학력"
        badgeText={educations.educationLevelText}
      />
      <EduContents educationList={educations.educations} />
    </div>
  );
};

export default Edu;
