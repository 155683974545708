import Icon from '@components/common/assets';
import PreviewProfileTitle from '@components/position/jd/apply/modals/previewProfileModal/previewProfileTitle';
import AttachedFileContents from '@components/profile/userInfoItems/attachedFile/AttachedFileContents';
import ProfileFileVO from '@domain/vo/profile/ProfileFileVO';

import classnames from 'classnames/bind';
import styles from './previewProfileModal.module.scss';
const cx = classnames.bind(styles);

interface AttachedFileProps {
  profileAttachedFile: ProfileFileVO | null;
}

const AttachedFile = ({ profileAttachedFile }: AttachedFileProps) => {
  if (!profileAttachedFile) return null;

  return (
    <div className={cx('viewWrap')}>
      <PreviewProfileTitle icon={<Icon name="folderLight" width={40} height={40} />} title="첨부 자료" />
      <AttachedFileContents profileAttachedFile={profileAttachedFile} />
    </div>
  );
};

export default AttachedFile;
