import React, { FC } from 'react';
import Icon from '@common/assets';
import { ExperienceVO } from '@domain/vo/profile/ProfileExperienceVO';

import classnames from 'classnames/bind';
import styles from './experience.module.scss';
const cx = classnames.bind(styles);

interface IDescriptionProps {
  description: string;
}

const Description: FC<IDescriptionProps> = ({ description }) => {
  if (!description.length) return null;

  return <div className={cx('description')}>{description}</div>;
};

interface IExperienceProps {
  experience: ExperienceVO;
}

const Experience: FC<IExperienceProps> = ({ experience }) => {
  const { tagText, title, startDate, endDate, description, auditItems } = experience;
  const isAutoSaved =
    auditItems?.includes('PROFILE_ACTIVITY_START_DATE') ||
    auditItems?.includes('PROFILE_ACTIVITY_END_DATE') ||
    auditItems?.includes('PROFILE_FOREIGN_EXPERIENCE_START_DATE') ||
    auditItems?.includes('PROFILE_FOREIGN_EXPERIENCE_END_DATE');

  return (
    <div className={cx('infoWrap')}>
      {/* 활동명 */}
      <em className={cx('name')}>
        {/* 경험 태그 */}
        <span className={cx('tag')}>{tagText}</span>
        {title}
      </em>
      {/* 활동 기간 */}
      <div className={cx('period')}>
        <Icon name="calenderLight" width={24} height={24} />
        <span className={cx({ autoSave: isAutoSaved })}>{`${startDate} ~ ${endDate}`}</span>
      </div>
      {/* 활동 내용 */}
      <Description description={description} />
    </div>
  );
};

interface IExperienceListProps {
  experienceList: ExperienceVO[];
}

const ExperienceList: FC<IExperienceListProps> = ({ experienceList }) => {
  if (!experienceList.length) return null;

  const experienceListEl = experienceList.map((item, idx) => {
    return <Experience key={idx} experience={item} />;
  });

  return <>{experienceListEl}</>;
};

export default ExperienceList;
