import Icon from '@components/common/assets';
import PreviewProfileTitle from '@components/position/jd/apply/modals/previewProfileModal/previewProfileTitle';
import PrizeContents from '@components/profile/userInfoItems/prize/PrizeContents';
import ProfilePrizeVO from '@domain/vo/profile/ProfilePrizeVO';

import classnames from 'classnames/bind';
import styles from './previewProfileModal.module.scss';
const cx = classnames.bind(styles);

interface PrizeProps {
  profilePrize: ProfilePrizeVO | null;
}

const Prize = ({ profilePrize }: PrizeProps) => {
  if (!profilePrize) return null;

  return (
    <div className={cx('viewWrap')}>
      <PreviewProfileTitle icon={<Icon name="diamondLight" width={40} height={40} />} title="수상 내역" />
      <PrizeContents prizes={profilePrize.prizes} />
    </div>
  );
};

export default Prize;
