import React, { FC } from 'react';
import Icon from '@common/assets';
import FileRs from '@domain/rs/common/FileRs';
import { useDownloadFile } from '@repository/file/useFiles';
import ProfileFileVO from '@domain/vo/profile/ProfileFileVO';

import TextFormat from '@utils/text/format';
import FileUtil from '@utils/fileUtil';
import classnames from 'classnames/bind';
import styles from './attachedFile.module.scss';
const cx = classnames.bind(styles);

interface IAttachFileContentsProps {
  title: string;
  attachFiles: FileRs[];
  onDownloadFile: (fileInfo: FileRs) => void;
}

const AttachFileContents: FC<IAttachFileContentsProps> = ({ title, attachFiles, onDownloadFile }) => {
  if (!attachFiles.length) return null;

  const attachFilesEl = attachFiles.map((item) => {
    return (
      <a key={item.fileUid} className={cx('file')} role={'button'} onClick={() => onDownloadFile(item)}>
        <Icon name="linkLineLight" width={24} height={24} />
        <span className={cx('fileName')}>{item.fileName}</span>
      </a>
    );
  });

  return (
    <div className={cx('infoWrap')}>
      <em className={cx('infoTitle')}>{title}</em>

      {/* 첨부 파일 영역 */}
      <div className={cx('fileArea')}>{attachFilesEl}</div>
    </div>
  );
};

interface IUrlContentsProps {
  referenceUrls: string[];
}

const UrlContents: FC<IUrlContentsProps> = ({ referenceUrls }) => {
  if (!referenceUrls.length) return null;

  const urlsEl = referenceUrls.map((item, idx) => {
    const url = TextFormat.formatProtocol(item);
    return (
      <a key={idx} href={url} className={cx('file')} target="_blank" rel="noreferrer">
        <Icon name="linkLineLight" width={24} height={24} />
        <span className={cx('fileName')}>{item}</span>
      </a>
    );
  });

  return (
    <div className={cx('infoWrap')}>
      <em className={cx('infoTitle')}>URL</em>

      <div className={cx('fileArea')}>{urlsEl}</div>
    </div>
  );
};

interface IAttachedFileContentsProps {
  profileAttachedFile: ProfileFileVO;
}

const AttachedFileContents: FC<IAttachedFileContentsProps> = ({ profileAttachedFile }) => {
  const { referenceUrls, portfolioAttachFiles, careerAttachFiles } = profileAttachedFile;

  const { mutate: downloadFileMutate } = useDownloadFile();

  const onDownloadFile = async (fileInfo: FileRs) => {
    if (fileInfo?.fileUrl) {
      return FileUtil.fileUrlDownload(fileInfo.fileName, fileInfo?.fileUrl);
    }
    await downloadFileMutate(fileInfo);
  };

  return (
    <div className={cx('infoInner')}>
      <AttachFileContents title={'포트폴리오'} attachFiles={portfolioAttachFiles} onDownloadFile={onDownloadFile} />
      <AttachFileContents title={'경력기술서'} attachFiles={careerAttachFiles} onDownloadFile={onDownloadFile} />
      <UrlContents referenceUrls={referenceUrls} />
    </div>
  );
};

export default AttachedFileContents;
