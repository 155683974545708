import React, { FC } from 'react';
import ProfileSkillVO, { ExaminationVO, SkillVO } from '@domain/vo/profile/ProfileSkillVO';
import { LicenseDto } from '@domain/rs/profile/ProfileSkillRs';

import classnames from 'classnames/bind';
import styles from './skill.module.scss';
const cx = classnames.bind(styles);

interface ILicenseContentsProps {
  licenseList: LicenseDto[];
}

const LicenseContents: FC<ILicenseContentsProps> = ({ licenseList }) => {
  if (!licenseList.length) return null;

  const licenseListEl = licenseList.map((item) => {
    return (
      <li key={item.code} className={cx('listItem')}>
        {item.name}
      </li>
    );
  });

  return (
    <div className={cx('infoWrap')}>
      <em className={cx('infoTitle')}>자격증</em>

      <div className={cx('detailInfoWrap')}>
        <ul className={cx('detailInfoList')}>{licenseListEl}</ul>
      </div>
    </div>
  );
};

interface IExaminationContentsProps {
  examinationList: ExaminationVO[];
}

const ExaminationContents: FC<IExaminationContentsProps> = ({ examinationList }) => {
  if (!examinationList.length) return null;

  const examinationListEl = examinationList.map((item) => {
    return (
      <li key={item.code} className={cx('listItem')}>
        {item.labelText}
      </li>
    );
  });

  return (
    <div className={cx('infoWrap')}>
      <em className={cx('infoTitle')}>공인 어학 시험</em>

      <div className={cx('detailInfoWrap')}>
        <ul className={cx('detailInfoList')}>{examinationListEl}</ul>
      </div>
    </div>
  );
};

interface ISkillKeywordContentsProps {
  skillKeywordList: SkillVO[];
}

const SkillKeywordContents: FC<ISkillKeywordContentsProps> = ({ skillKeywordList }) => {
  if (!skillKeywordList.length) return null;

  const skillKeywordListEl = skillKeywordList.map((item) => {
    return (
      <li key={item.code} className={cx('listItem')}>
        {item.labelText}
      </li>
    );
  });

  return (
    <div className={cx('infoWrap')}>
      <em className={cx('infoTitle')}>기술 키워드</em>

      <div className={cx('detailInfoWrap')}>
        <ul className={cx('detailInfoList')}>{skillKeywordListEl}</ul>
      </div>
    </div>
  );
};

interface ISkillContentsProps {
  profileSkill: ProfileSkillVO;
}

const SkillContents: FC<ISkillContentsProps> = ({ profileSkill }) => {
  const { licenses, examinations, skills } = profileSkill;

  return (
    <div className={cx('infoInner')}>
      <LicenseContents licenseList={licenses} />
      <ExaminationContents examinationList={examinations} />
      <SkillKeywordContents skillKeywordList={skills} />
    </div>
  );
};

export default SkillContents;
