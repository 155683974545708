import Icon from '@components/common/assets';
import BasicInfo from '@components/position/jd/apply/modals/previewProfileModal/basicInfo';
import Edu from '@components/position/jd/apply/modals/previewProfileModal/edu';
import Career from '@components/position/jd/apply/modals/previewProfileModal/career';
import Project from '@components/position/jd/apply/modals/previewProfileModal/project';
import Skill from '@components/position/jd/apply/modals/previewProfileModal/skill';
import Experience from '@components/position/jd/apply/modals/previewProfileModal/experience';
import Prize from '@components/position/jd/apply/modals/previewProfileModal/prize';
import MilitaryPreference from '@components/position/jd/apply/modals/previewProfileModal/militaryPreference';
import AttachedFile from '@components/position/jd/apply/modals/previewProfileModal/attachedFile';

import ProfileBasicVO from '@domain/vo/profile/ProfileBasicVO';
import ProfileEducationVO from '@domain/vo/profile/ProfileEducationVO';
import ProfileCareersVO from '@domain/vo/profile/ProfileCareersVO';
import ProfileProjectsVO from '@domain/vo/profile/ProfileProjectVO';
import ProfilePrizeVO from '@domain/vo/profile/ProfilePrizeVO';
import ProfileSkillVO from '@domain/vo/profile/ProfileSkillVO';
import ProfileExperienceVO from '@domain/vo/profile/ProfileExperienceVO';
import ProfilePreferentialVO from '@domain/vo/profile/ProfilePreferentialVO';
import ProfileFileVO from '@domain/vo/profile/ProfileFileVO';

import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './previewProfileModal.module.scss';
const cx = classnames.bind(styles);

interface Props {
  basicInfo: Pick<
    ProfileBasicVO,
    'name' | 'profileImageUrl' | 'email' | 'birthDateText' | 'mobileText' | 'countryCode'
  > | null;
  educations: ProfileEducationVO | null;
  career: ProfileCareersVO | null;
  projects: ProfileProjectsVO | null;
  prize: ProfilePrizeVO | null;
  knowledgeAndSkill: ProfileSkillVO | null;
  experience: ProfileExperienceVO | null;
  preferential: ProfilePreferentialVO | null;
  file: ProfileFileVO | null;
  onClose?: () => void;
}

const PreviewProfile = ({
  basicInfo,
  educations,
  career,
  projects,
  knowledgeAndSkill,
  experience,
  prize,
  preferential,
  file,
  onClose,
}: Props) => {
  return (
    <>
      {/* 기본 */}
      <BasicInfo basicInfo={basicInfo} />

      {/* 학력 */}
      <Edu educations={educations} />

      {/* 경력 */}
      <Career career={career} />

      {/* 프로젝트 */}
      <Project profileProjects={projects} />

      {/* 지식 · 기술 */}
      <Skill profileSkill={knowledgeAndSkill} />

      {/* 경험 */}
      <Experience profileExperience={experience} />

      {/* 수상 내역 */}
      <Prize profilePrize={prize} />

      {/* 병역 · 취업 우대 */}
      <MilitaryPreference profilePreferential={preferential} />

      {/* 첨부 자료 */}
      <AttachedFile profileAttachedFile={file} />

      {!!onClose && (
        <a className={cx('btnClose')} role="button" onClick={onClose}>
          <Icon name="closeLight" width={32} height={32} fill={Colors.C_WHITE} />
          <span className={cx('hidden')}>close</span>
        </a>
      )}
    </>
  );
};

export default PreviewProfile;
