import classnames from 'classnames/bind';
import styles from './previewProfileTitle.module.scss';
const cx = classnames.bind(styles);

interface PreviewProfileTitleProps {
  icon: React.ReactNode;
  title: string;
  badgeText?: string;
}

const PreviewProfileTitle = ({ icon, title, badgeText }: PreviewProfileTitleProps) => {
  return (
    <>
      <div className={cx('titleArea')}>
        {icon}
        <span className={cx('title')}>{title}</span>
        {badgeText && <span className={cx('badge')}>{badgeText}</span>}
      </div>
    </>
  );
};

export default PreviewProfileTitle;
