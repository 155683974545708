import Icon from '@components/common/assets';
import PreviewProfileTitle from '@components/position/jd/apply/modals/previewProfileModal/previewProfileTitle';
import SkillContents from '@components/profile/userInfoItems/skill/SkillContents';
import ProfileSkillVO from '@domain/vo/profile/ProfileSkillVO';

import classnames from 'classnames/bind';
import styles from './previewProfileModal.module.scss';
const cx = classnames.bind(styles);

interface SkillProps {
  profileSkill: ProfileSkillVO | null;
}

const Skill = ({ profileSkill }: SkillProps) => {
  if (!profileSkill) return null;

  return (
    <div className={cx('viewWrap')}>
      <PreviewProfileTitle icon={<Icon name="designLight" width={40} height={40} />} title="지식 · 기술" />
      <SkillContents profileSkill={profileSkill} />
    </div>
  );
};

export default Skill;
