import React, { FC } from 'react';
import Icon from '@components/common/assets';
import { CareersVO } from '@domain/vo/profile/ProfileCareersVO';

import classnames from 'classnames/bind';
import styles from './career.module.scss';
const cx = classnames.bind(styles);

interface IDescriptionProps {
  performance: string | null;
}

const Description: FC<IDescriptionProps> = ({ performance }) => {
  if (!performance?.length) return null;

  return <div className={cx('description')}>{performance}</div>;
};

interface ICareerItemProps {
  career: CareersVO;
}

const CareerItem: FC<ICareerItemProps> = ({ career }) => {
  const {
    companyName,
    employmentStatusText,
    startDate,
    endDate,
    employmentTypeText,
    performance,
    role,
    jobGroupName,
    jobTitleName,
    auditItems,
  } = career;
  const isDateAutoSaved =
    auditItems?.includes('PROFILE_CAREER_START_DATE') ||
    auditItems?.includes('PROFILE_CAREER_END_DATE') ||
    auditItems?.includes('PROFILE_CAREER_EMPLOYMENT_STATUS');
  const dateText = employmentStatusText ? `${startDate} ~ ` : `${startDate} ~ ${endDate}`;

  return (
    <div className={cx('infoWrap')}>
      {/* 직장 이름 */}
      <em className={cx('name')}>{companyName}</em>
      {/* 근무 기간 */}
      <div className={cx('period')}>
        <Icon name="calenderLight" width={24} height={24} />
        <span className={cx({ autoSave: isDateAutoSaved })}>
          {dateText}
          {employmentStatusText && <span className={cx('emphasis')}>재직중</span>}
        </span>
      </div>

      {/* 역할 / 계약 형태 */}
      <div className={cx('typeArea')}>
        <span className={cx('jobTitleArea', { autoSave: auditItems?.includes('PROFILE_CAREER_JOB_TITLE') })}>
          <div>{jobGroupName}</div>
          <div className={cx('jobTitle')}>{jobTitleName}</div>
        </span>
        {!!role && <div className={cx('role')}>{role}</div>}
        <div className={cx('type', { autoSave: auditItems?.includes('PROFILE_CAREER_EMPLOYMENT_TYPE') })}>
          {employmentTypeText}
        </div>
      </div>
      {/* 주요 성과 */}
      <Description performance={performance} />
    </div>
  );
};

interface ICareerContentsProps {
  careerList: CareersVO[];
}

const CareerContents: FC<ICareerContentsProps> = ({ careerList }) => {
  const contents = careerList.map((item, idx) => {
    return <CareerItem key={idx} career={item} />;
  });

  return <div className={cx('infoInner')}>{contents}</div>;
};

export default CareerContents;
